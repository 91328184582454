<template>
    <div>
        <b-row>
            <b-col cols="12">
                <b-form-group :label="$t('name')">
                    <b-input-group>
                        <div class="label-as-input">{{ isNotNullValue(form.name) }}</div>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <b-col cols="12">
                <b-form-group :label="$t('code')">
                    <b-input-group>
                        <div class="label-as-input">{{ isNotNullValue(form.code) }}</div>
                    </b-input-group>
                </b-form-group>
            </b-col>

            <b-col cols="12">
                <b-form-group :label="$t('class')">
                    <b-input-group>
                        <div class="label-as-input">{{ isNotNullValue(form.class) }}</div>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <b-col cols="12">
                <b-form-group :label="$t('type')">
                    <b-input-group>
                        <div class="label-as-input">{{ isNotNullValue(form.type) }}</div>
                    </b-input-group>
                </b-form-group>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    //Service
    import MedicinegroupService from "@/services/MedicinegroupService";

    export default {
        data() {
            return {
                form: {
                    code: null,
                    name: null,
                    class: null,
                    type: null
                }
            }
        },
        props: {
            formId: {
                type: Number,
            }
        },
        async created() {
            await this.get(this.formId)
        },
        methods: {
            get(id) {
                MedicinegroupService.get(id)
                                    .then((response) => {
                                        this.form = response.data.data;
                                    })
                                    .catch((error) => {
                                        this.showErrors(error)
                                    })
            },
        }
    }
</script>
